@use 'styles/variables' as var;
@use 'styles/functions' as fn;

.icon-list-item {
  list-style: none;

  svg {
    stroke: #3b6af7;
  }

  path {
    stroke: #3b6af7;
  }
}

.icon-item {
  display: flex;
  text-align: left;
  flex-flow: row nowrap;
  margin-bottom: 12px;
}

.icon-item-svg {
  margin-right: 12px;
  margin-top: 4px;
}

.icon-item p {
  display: contents;
}

.icon-item[data-no-margin-bottom='true'] {
  margin-bottom: 0;
}

.no-margin-bottom {
  margin-bottom: 0;
}
