@use 'styles/variables' as var;
@use 'styles/functions' as fn;

.container {
  border-top: 0.75px solid #c0cbd8;
  border-bottom: 0.75px solid #c0cbd8;
  padding: 16px 0;
  margin-top: 32px;
}

.title-eyebrow {
  text-align: left;
  padding-bottom: 0;
  color: #5c708a;
}

.image {
  border-radius: 8px;
}

.content-text {
  color: #39485b;
}

.container-img {
  padding: 0;
  margin-bottom: 16px;
}

@media only screen and (min-width: 1024px) {
  .container-img {
    padding: 16px;
    margin-bottom: 0;
  }
}
