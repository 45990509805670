@use 'styles/variables' as var;
@use 'styles/functions' as fn;

.container-grid {
  color: var.$color-neutral-300;
  background-color: var.$color-blue-50;
  padding: 32px 12px 16px;
  border-radius: var.$achieve-default-border-radius;
  margin-bottom: 32px;
}

@media screen and (min-width: 1024px) {
  .container-grid {
    padding: 32px fn.spacing(11) 32px fn.spacing(11);
  }

  .text {
    margin: 16px 0;
  }
}
