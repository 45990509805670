@use 'styles/variables' as var;

.background-button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  position: relative;
  color: #fff;
  font-size: 40px;
  z-index: 3 !important;
}

.background-button img {
  cursor: pointer;
}
