@use 'styles/variables' as var;
@use 'styles/functions' as fn;

.main-nav-unnested-chevron {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.main-nav {
  background: #fff;
  border-top: 1px solid var.$color-border-opaque;
  height: 100%;
  overflow: scroll;
  padding-bottom: 160px;
  padding-top: 54px;
  position: fixed;
  right: -100%;
  transition: transform var.$default-animation-timing;
  width: 100%;
}

.no-link-item {
  font-family: inherit;
  background-color: #fff;
  border: 0;
  width: 100%;
}

.main-nav[data-mobile-nav-open='true'] {
  transform: translateX(-100%);
}

.main-nav > ul {
  display: flex;
  flex-direction: column;
  list-style: none;
  margin: 0;
  padding: 0;
}

.main-nav-item-title {
  align-items: center;
  color: #1d252f;
  display: flex;
  height: 50px;
  justify-content: center;
  justify-content: space-between;
  transition: var.$default-animation-timing;
}

.main-nav-item-icon {
  transition: var.$default-animation-timing;
}

.main-nav-item {
  border-bottom: 1px solid var.$color-border-opaque;

  a {
    text-decoration: none;
  }

  &[data-selected='true'] {
    .main-nav-item-title {
      color: #30f;
    }
  }

  &[data-active='true'] {
    .main-nav-item-icon {
      transform: rotate(180deg);
    }
  }
}

.main-nav-item > a,
.main-nav-item > .no-link-item {
  cursor: pointer;
  display: block;
  text-decoration: none;
  transition: var.$default-animation-timing;
  padding: 0 16px;
}

.sub-nav {
  overflow: hidden;
  transition: all var.$default-animation-timing;
}

.sub-nav-menu ul {
  list-style: none;
  margin: 0;
  padding: 0 (32px);
}

.sub-nav-menu li {
  margin-bottom: 16px; // 16px
  margin-top: 16px; // 16px
  // manually putting in color from old ascend, 6/24 demo, should use color vars
}

.sub-nav-menu-without-subtitle li {
  margin-bottom: 16px; // 16px
  margin-top: 16px; // 16px
  // manually putting in color from old ascend, 6/24 demo, should use color vars

  &:first-child {
    margin-top: 8px;
  }
}

.sub-nav-menu-without-subtitle ul {
  list-style: none;
  margin: 0;
  padding: 0 (32px);
}

.sub-nav-link {
  color: #1d252f;

  &:hover div:first-child,
  &:focus div:first-child {
    color: #30f;
  }

  &[data-selected='true'] div:first-child {
    color: #30f;
  }
}

.sub-nav-link-description {
  color: #42546b;
}

.main-nav-item > a:last-child,
.main-nav-item > .no-link-item:last-child {
  border-bottom: none;
}

.menu-title {
  color: #42546b;
  padding: 8px 0 0 16px;
}

/* Desktop (large or greater) styles */
@media screen and (min-width: 1024px) {
  .sub-nav-menu {
    width: 368px;

    & + .sub-nav-menu {
      margin-left: 64px; // 64px
    }

    li {
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  .sub-nav-menu-without-subtitle {
    width: 368px;

    & + .sub-nav-menu {
      margin-left: 64px; // 64px
    }

    li {
      margin-top: 0;
      margin-bottom: 0;

      &:last-child {
        margin-bottom: 24px;
      }

      &:first-child {
        margin-top: 24px;
      }
    }
  }

  .sub-nav-menu li:last-child {
    margin-bottom: 24px;
  }

  .main-nav {
    border: none;
    height: auto;
    overflow: initial;
    padding-bottom: 0;
    padding-top: 0;
    position: relative;
    right: 0;
    top: 0;
    transition: none;
    width: auto;
  }

  .main-nav[data-mobile-nav-open='true'] {
    transform: none;
  }

  .main-nav,
  .main-nav > ul,
  .main-nav-item {
    height: 100%;
  }

  .main-nav > ul {
    align-items: stretch;
    flex-direction: row;
  }

  .main-nav-item {
    align-items: stretch;
    border: 0;
    display: flex;
  }

  .main-nav-item > a,
  .main-nav-item > .no-link-item {
    align-items: center;
    border: 0;
    display: flex;
    padding: 0 16px;
  }

  .main-nav-item-title {
    height: 100%;
    overflow: visible;
    position: relative;
  }

  .main-nav-item[data-active='true'],
  .main-nav-item[data-selected='true'] {
    .main-nav-item-title {
      color: #30f;
    }
  }

  .main-nav-item[data-selected='true'] {
    .main-nav-item-title::after {
      transition: bottom var.$default-animation-timing, width var.$default-animation-timing;
      background-color: #30f;
      border-radius: 4px;
      bottom: fn.spacing(-2.5); // 18px - needs to offset the height of the border
      content: '';
      height: 4px;
      position: absolute;
      width: 100%;
    }
  }

  [data-condensed='true'] .main-nav-item[data-selected='true'] .main-nav-item-title::after {
    bottom: fn.spacing(-1.25); // 10px - needs to offset the height of the border
  }

  .sub-nav {
    background-color: #fff;
    border-radius: 8px;
    border: 1px solid var.$color-neutral-75;
    box-shadow: 0 1px 14px rgb(0 0 0 / 12%);
    display: none;
    position: absolute;
    transform: translate(-(24px), (96px)); // -24px, 96px
    a {
      text-decoration: none;
    }
  }

  [data-active='true'] .sub-nav {
    display: flex;
  }

  .sub-nav ul {
    padding: 0;
  }

  .main-nav[data-condensed='true'] .sub-nav {
    transform: translate(-(24px), (64px)); // -24px, 64px
  }

  .main-nav[data-condensed='true'] .main-nav-item > a span,
  .main-nav[data-condensed='true'] .main-nav-item > .no-link-item span {
    font-size: 15px;
  }

  .sub-nav-link {
    display: block;
    padding: 12px fn.spacing(5); // 12px 40px

    &:hover,
    &:focus {
      background-color: var.$color-blue-50;
    }
  }

  .menu-title-container {
    margin-bottom: 16px;
    padding: 32px fn.spacing(5) 0;
  }

  .menu-title {
    border-bottom: 1px solid var.$color-neutral-75;
    display: block;
    padding: 0 0 16px;
  }
}
