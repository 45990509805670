@use 'styles/variables' as var;

.achieve-section-container {
  &[data-narrow='true'] {
    padding-left: var.$default-gutter-width;
    padding-right: var.$default-gutter-width;
    max-width: var.$maximum-site-content-width-narrow;
  }
}

@media screen and (min-width: 768px) {
  .achieve-section-container {
    &[data-narrow='true'] {
      padding-left: var.$medium-and-up-gutter-width;
      padding-right: var.$medium-and-up-gutter-width;
    }
  }
}
