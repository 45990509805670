@use 'styles/variables' as var;
@use 'styles/functions' as fn;

.header {
  background: #fff;
  box-shadow: 0 0.5px 3px rgb(16 24 40 / 9%), 0 1px 1px rgb(16 24 40 / 3%);
  position: fixed;
  width: 100%;
  z-index: 15;
}

.logo-container {
  display: flex;
  align-items: center;
  margin: 0 16px;
}

.logo-flex {
  display: flex;
}

.logo-image {
  height: 100%;
  width: 100%;
}

.logo-svg {
  width: 100%;
  /* Make the SVG take up the full width of the container */
  height: auto;
  /* Maintain aspect ratio */
  min-width: 118px;
  /* Prevent the SVG from shrinking below a certain size */
}

.header-content {
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
}

.mobile-logo img {
  width: 118px !important;
  height: inherit !important;
  min-width: inherit !important;
  max-width: inherit !important;
}

.header-content-container {
  max-width: var.$maximum-site-content-width;

  &[data-narrow='true'] {
    max-width: var.$maximum-site-content-width-narrow;
  }
}

[data-mobile-nav-open='true'] .header-content {
  transition: height 0s;
  height: 100vh;
}

.mobile-header {
  align-items: center;
  background: #fff;
  display: flex;
  flex-direction: row;
  height: 72px;
  justify-content: space-between;
  padding-left: var.$default-gutter-width;
  // Reduced right padding accommodates for the mobile nav button touch target extra padding
  padding-right: var.$default-gutter-width / 2;
  width: 100%;
  z-index: 2;
  transition: height 0.1s linear 0.1s;
}

[data-mobile-nav-open='true'] .mobile-header,
[data-mobile-condensed='true'] .mobile-header {
  height: 54px;
}

[data-mobile-nav-open='true'] .mobile-header {
  border-bottom: 1px solid var.$color-border-opaque;
}

.logo {
  height: 29px;
  width: 119px;
  display: flex;
  flex-grow: 0;
  flex-shrink: 0;
  position: relative;
  transition: var.$default-animation-timing;
}

.partner-logo {
  width: 120px;
  height: 100%;
}

.mobile-logo {
  flex-grow: 0;
  flex-shrink: 0;
  height: 29px;
  position: absolute;
  transition: var.$default-animation-timing;
  width: 36px;
  overflow: hidden;
}

[data-product='website-wfs'] {
  .logo {
    height: 62px;
  }

  [data-mobile-condensed='false'] .logo {
    padding-top: 0;
  }

  [data-mobile-condensed='true'] .logo {
    height: 62px;
    padding-top: fn.spacing(1);
  }

  .mobile-logo {
    height: 40px;
    width: 120px;
    overflow: visible;
  }
}


/**
 * Conditionally hide header elements by sliding them vertically up out of the browser window.
 * - Hide the main logo when the mobile nav is open
 * - Hide the main logo when the header is condensed on mobile
 * - Hide the mobile header cta button when the mobile nav is open
 * - Hide the mobile header cta button when the header is not condensed on mobile
 * - Hide the mobile logo anytime the header is not condensed on mobile or the mobile nav is closed
 */
[data-mobile-nav-open='true'] .logo,
[data-mobile-condensed='true'] .logo,
[data-mobile-condensed='false'][data-mobile-nav-open='false'] .mobile-logo {
  transform: translateY(-90px);
}

[data-condensed='true'] .logo-container,
[data-condensed='false'] .logo-container,
[data-mobile-condensed='false'] .logo-container {
  margin: 0 16px;
}

[data-mobile-condensed='true'] .logo-container {
  margin: 0 0 0 -60px;
}

.mobile-menu-controls {
  align-items: center;
  display: flex;
  height: 34px;
  justify-content: flex-end;
  position: relative;
  width: 100%;
}

.mobile-menu-button,
.mobile-close-button {
  align-items: center;
  background: none;
  border: none;
  color: #1d252f;
  display: flex;
  padding: 11px 8px; // Increases the hit area of the mobile buttons
  position: absolute;
  transition: var.$default-animation-timing;
}

.mobile-close-button span {
  margin-right: 12px;
  text-decoration: none;
}

[data-mobile-nav-open='true'] {
  .mobile-menu-button {
    transform: translateY(-80px);
  }
}

[data-mobile-nav-open='false'] {
  .mobile-close-button {
    transform: translateX(200px);
  }
}

.mobile-header-actions {
  background: #fff;
  bottom: fn.spacing(-10);
  position: fixed;
  transition: transform var.$default-animation-timing;
  width: 100%;
  z-index: 3;
}

[data-mobile-nav-open='true'] .mobile-header-actions {
  padding: 16px;
  box-shadow: 0 1px 14px rgb(0 0 0 / 12%);
  transform: translateY(-80px);
}

.mobile-header-actions-sign-in-link-container {
  width: auto;
}

.mobile-header-actions-sign-in-link {
  display: inline-block;
}

.mobile-header-actions-cta {
  display: inline-block;
  width: 100%;
  text-align: center;

  &:hover {
    text-decoration: none !important;
  }
}

.sign-in-link {
  display: none;
}

.header-right-section {
  display: flex;
  align-items: center;
  position: absolute;
  right: 95px; // Adjust the cta to allow space for the menu button
  top: 10px; // Vertically center the mobile header cta within the mobile condensed header
  transition: var.$default-animation-timing;
  z-index: 2;
  gap: 16px;
  overflow: hidden;
  transform: translateY(0);

  button {
    transition: var.$default-animation-timing;
  }
}

[data-none-phone-number='true'].header-right-section {
  right: 50px;
}

[data-none-phone-number='false'].header-right-section {
  justify-content: flex-end;
}

.call-cta {
  display: flex;
  justify-content: left;
  align-items: center;
  padding: 8px;
  background: var.$color-neutral-50;
  border-radius: 24px;
  cursor: pointer;
  color: #30f;
  gap: 8px;
  overflow: hidden;
  transition: all 300ms;
  transition-delay: 150ms;
  z-index: 2;
}

[data-mobile-condensed='true'] .call-cta,
[data-condensed='true'] .call-cta {
  padding: 4px;
  height: 34px;
}

[data-condensed='true'] [data-open-button-call='false'] .call-cta {
  width: 34px;
}

[data-mobile-nav-open='true'] .header-right-section,
[data-mobile-condensed='false'] .header-right-section {
  transform: translateY(-70px);
  transition: all var.$default-animation-timing;
}

.header-subnav {
  padding: 24px;
  background: var.$color-neutral-50;
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
}

.call-cta-link {
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.button-call-link {
  text-wrap: nowrap;
  width: 125px;
  display: flex;
  justify-content: center;
  opacity: 0;
  transition-delay: 0ms;
  transition-property: opacity;

  span {
    line-height: 100%;
  }
}

.call-cta-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  transition: all 300ms;
}

[data-open-button-call='false'] .call-cta .call-cta-container {
  padding-left: 12%;
}

[data-call-cta-mobile='true'].call-cta {
  position: absolute;
  right: 40px;
}

[data-mobile-nav-open='true'] [data-call-cta-mobile='true'].call-cta {
  display: none;
}

[data-open-button-call='true'] .button-call-link {
  opacity: 1;
  transition-delay: 310ms;
}

.header-cta {
  display: inline-block;
  text-wrap: nowrap;
  opacity: 0;
  transition-delay: 0ms;
  transition-property: opacity;
  position: absolute;
  width: 0;

  button {
    transition: all var.$default-animation-timing;
    z-index: 1;
  }

  &:hover {
    text-decoration: none;
  }
}

[data-condensed='false'] .header-cta button {
  padding: 8px 16px;
}

.header-content-container .header-content .header-cta>button:hover {
  color: #30f;
  background-color: #94bbfe;
}

.header-content-container .header-content .sign-in-link:hover {
  color: #30f;
}

[data-open-button-call='false'] .header-cta {
  opacity: 1;
  width: auto;
  position: relative;
}

.phone-icon {
  object-fit: none;
}

.progress-page {
  width: 100%;
  height: 3px;
  position: absolute;
  background: linear-gradient(to bottom, rgb(0 0 0 / 20%), rgb(0 0 0 / 0%), rgb(0 0 0 / 0%));
}

.progress-page .progress {
  content: '';
  position: absolute;
  height: 3px;
  background: var.$color-blue-400;
}

.header[data-product='website-tenant-2'],
.header[data-product='website-ffn'] {
  .mobile-logo {
    height: 30px;
    width: 70px;
  }
}

[data-product='website-ffn'], [data-product='achieve'][data-slug='debt-consolidation'] {
  .logo {
    height: 40px;
  }

  .call-cta {
    border-radius: 0;
    background: transparent;
    padding: 0;
  }

  .phone-icon {
    object-fit: contain;
  }

  [data-call-cta-mobile='true'].call-cta {
    right: 10px;
  }
}

/* Desktop (large or greater) styles */
@media screen and (min-width: 1024px) {
  .partner-logo {
    width: 180px;
    height: 100%;
  }

  .phone-icon-desktop p {
    font-weight: bold;
  }

  .phone-icon-desktop span {
    color: var.$color-blue-800;
  }

  .header {
    box-shadow: none;

    &[data-condensed='true'] {
      box-shadow: 0 6px 40px -4px rgb(16 24 40 / 11%), 0 9px 12px -3px rgb(16 24 40 / 5%);
    }

    &[data-product='website-tenant-2'],
    &[data-product='website-ffn'] {
      [data-selected='true'] ::after {
        content: inherit;
      }

      [data-nav='item-title'] {
        font-size: 13px;
        font-weight: 100;
        min-width: 110px;
        text-align: center;
        display: block;
      }

      [data-nav='sub-nav'] div {
        font-size: 13px;
        font-weight: 100;
      }

      [data-nav='sub-nav'] {
        padding: fn.spacing(0.8) fn.spacing(2);
      }

      [data-nav='sub-nav-container'] {
        overflow: initial;
        transform: translate(-110px, 69px);
      }

      [data-condensed='true'] {
        [data-nav='sub-nav-container'] {
          transform: translate(-110px, 55px);
        }
      }

      [data-nav='sub-nav-container']::after {
        background-color: #fff;
        box-shadow: -2.5px -2.5px 2px 0 rgba(0 0 0 / 10%), inset 0 1px 3px 0 transparent;
        left: 0;
        margin: 0 auto;
        right: 0;
        content: '';
        height: 0.9285rem;
        position: absolute;
        top: -0.4rem;
        transform: rotate(45deg);
        width: 0.9285rem;
      }

      [data-nav='container'] {
        li:first-child {
          margin-top: fn.spacing(1);
        }

        li:last-child {
          margin-bottom: fn.spacing(1);
        }
      }

      .logo {
        height: 40px;
        width: 90px;
      }
    }
  }

  .header-content-container {
    // These paddings normally apply on the medium breakpoint, but due to custom considerations for
    // the header, they don't apply until the design switches to the large designs.
    padding-left: var.$medium-and-up-gutter-width;
    padding-right: var.$medium-and-up-gutter-width;
  }

  .mobile-header {
    background: none;
    padding: 0;
    position: relative;
    width: auto;
  }

  .mobile-logo {
    display: none;
  }

  .header-content {
    align-items: center;
    flex-direction: row;
    height: 96px; // 96px
    justify-content: space-between;
    overflow: visible;
    transition: height var.$default-animation-timing;
  }

  // Use the default header height, ignoring if mobile nav flag is set to open
  [data-mobile-nav-open='true'] .header-content {
    height: 96px; // 96px
  }

  // Use the condensed header height in any instance where[data-condensed='true']
  [data-condensed='true'] .header-content,
  [data-mobile-nav-open='true'][data-condensed='true'] .header-content {
    height: 64px; // 64px
  }

  [data-mobile-nav-open='true'] .mobile-header {
    border: 0;
    height: auto;
    position: relative;
  }

  .logo {
    height: 60px;
    transform: none;
    width: 180px;
  }

  [data-mobile-condensed='true'] .logo,
  [data-mobile-nav-open='true'] .logo {
    transform: none;
  }

  [data-condensed='true'] .logo {
    height: 52px;
    width: 117px;
  }

  .mobile-menu-controls {
    display: none;
  }

  .mobile-menu-button,
  .mobile-close-button {
    display: none;
  }

  .sign-in-link {
    display: inline-block;
    min-width: 60px;
  }

  .header-right-section {
    position: relative;
    top: initial;
    right: initial;
    min-width: 280px;
    max-width: 280px;
    overflow: hidden;
    justify-content: flex-end;
  }

  [data-open-button-call='true'] .call-cta {
    border-radius: 24px;
    padding: 8px 16px;
  }

  [data-mobile-condensed='false'] .header-right-section {
    display: flex;
    transform: translateY(0);
  }

  [data-condensed='false'] [data-open-button-call='false'] .call-cta {
    padding: 4px;
    min-width: 42px;
    min-height: 42px;
    width: 42px;
    height: 42px;
  }

  [data-condensed='false'] [data-open-button-call='true'] .call-cta {
    min-height: 42px;
    height: 42px;
  }

  .header-subnav {
    display: none;
  }
}