@use 'styles/variables' as var;
@use 'styles/functions' as fn;

.outer-wrapper-widget {
  display: contents;

  .container {
    border-radius: var.$achieve-default-border-radius;
    background-color: var.$color-blue-50;
  }

  .image {
    border-radius: var.$achieve-default-border-radius;
    height: 264px;
    width: 264px;
  }

  .cta-tile {
    padding: 24px 0;
  }

  .cta-subtitle {
    margin-top: 16px;
  }

  .cta-button {
    margin-top: 16px;
    margin-bottom: 32px;
  }

  .image-container {
    padding: 16px;
    border-radius: var.$achieve-default-border-radius;
  }

  .inner-container {
    margin-top: 8px;
  }

  .text-container {
    padding: 24px;
  }
}

.outer-wrapper-main {
  display: contents;

  .container {
    margin: 64px 0;
    padding: 32px;
    background-color: var.$color-blue-50;
    border-radius: var.$achieve-default-border-radius;
  }

  .text-container {
    flex-direction: column;
    text-align: center;
  }

  .cta-tile {
    padding: 24px 0;
    margin-bottom: 32px;
  }

  .cta-subtitle {
    margin-top: 16px;
  }

  .cta-button {
    margin-top: 32px;
  }
}
