@use 'styles/functions' as fn;
@use 'styles/variables' as var;

.icon-button {
  background-color: inherit;
  box-shadow: none;
  position: absolute;
  right: 8px;
  top: 8px;
  z-index: 1;
}

.dialog-content {
  overflow: hidden;
  padding: 0;
  margin: 0;
}

.button svg {
  color: #1d252f;
}

.button [class*='endIcon'] {
  margin-left: 8px;
}

.video-player-container {
  background-color: #1d252f;
  padding-top: 50px;
}

// Targets the dialog modal backdrop
.dialog-container > div {
  background-color: #1d252f;
}

@media only screen and (min-width: 768px) {
  // Targets the dialog modal backdrop
  .dialog-container > div {
    background-color: rgb(0 0 0 / 65%);
  }

  .video-player-container {
    padding-top: 0;
  }
}
