@use 'styles/variables' as var;
@use 'styles/functions' as fn;

.base-styles {
  &[data-variant='text'] {
    &:hover {
      text-decoration: underline;
      background: transparent;
    }
  }

  &.base-styles[data-variant='contained'] {
    &:hover {
      color: #30f;
      background-color: #94bbfe;
    }
  }
}
