@use 'styles/variables' as var;
@use 'styles/functions' as fn;

.eyebrow {
  color: #30f;
  text-align: center;
  padding-bottom: 16px;
  letter-spacing: 0.25em;
  text-transform: uppercase;
  line-height: 16.6px;
}

.eyebrow[data-variant='black'] {
  color: #5c708a;
}

@media only screen and (min-width: 1024px) {
  .eyebrow {
    padding-bottom: 32px;
  }
}
